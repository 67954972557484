.Home {
	flex: 1;
	background-color: #e5e5e5;
	display: flex;
	flex-direction: column;
	padding: 2rem;
}

.Home select {
	align-self: flex-end;
	border: none;
	border-radius: 5px;
	padding: 5px 8px;
	font-family: inherit;
	font-size: 14px;
	font-weight: 700;
}

.Home div {
	align-self: center;
	justify-self: center;
	color: #3d3d67;
	display: flex;
	flex-direction: column;
	height: 50vh;
	justify-content: center;
}

.Home button {
	width: 40%;
    margin: 5px auto;
}

.Button {
	background-color: #ef4e76;
	margin: 2rem auto;
	color: #fff;
	padding: 1rem 2.4rem;
	font-family: inherit;
	font-size: 1.6rem;
	text-transform: capitalize;
	font-weight: 700;
	border-radius: 3px;
}
