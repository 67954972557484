.Sidebar {
	width: 20%;
	position: relative;
	background-color: #fff;
	box-shadow: 3px 0 5px #e5e5e5;
	overflow: hidden;
	transition: all ease-in-out 0.4s;
}

.LogoContainer {
	height: 5rem;
	display: flex;
	align-items: center;
	margin-top: 3rem;
	justify-content: center;
	width: 100%;
}

.SidebarBtn {
	border: none;
	/* background-color: #353561; */
	background: transparent;
	outline: none;
	cursor: pointer;
}

.SidebarBtn svg {
	width: 2rem;
	height: 2rem;
	fill: #353561;
}

.Sidebar nav {
	/* position: fixed; */
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.Sidebar nav ul {
	margin-top: 5rem;
}

.Logo {
	width: 15rem;
	margin-right: 2rem;
	/* object-fit: contain; */
}

.NavItem {
	text-decoration: none;
	font-family: inherit;
	color: #000;
	font-size: 1.6rem;
	margin-left: 3rem;
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.NavItem .MainMenu {
	display: flex;
	align-items: center;
	padding: 1rem 0;
	padding-right: 1rem;
	width: 100%;
	justify-self: flex-end;
	margin-left: auto;
}

.NavItem svg {
	width: 2.5rem;
	height: 2.5rem;
	margin: 0 1.5rem;
}
