.main_container {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    font-size: 1.4rem
}

.main_container .badge {
    font-size: 1rem;
}

.main_container .rs-table-cell-content{
    font-size: 1.4rem;
}

.main_container button {
    font-size: 1.5rem;
}

.stat-spans {
    background: black;
	color: white;
	border-radius: 5px;
	font-size: 1.4rem;
	padding: 2px;
}


.modal-open .modal {
   
    opacity: 1
}