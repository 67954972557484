.ServerImages_main_container {
    width: 90%;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    font-size: 1.5rem;
    min-height: 100vh;
}

.ServerImages_main_container .MTableHeader-header-13{
    font-size: 1.3rem;
}