.NavItem {
	text-decoration: none;
	font-family: inherit;
	color: #000;
	font-size: 1.6rem;
	margin-left: 1.5rem;
	display: flex;
	flex-direction: column;

	margin-bottom: 1rem;
}

.NavItem:hover {
	text-decoration: none;
}

.NavItem .MainMenu {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 1rem 0;
	padding-right: 1rem;
	width: 100%;
	justify-self: flex-end;
	margin-left: auto;
	text-decoration: none;
	/* padding-left: 1rem; */
}

.NavItem svg {
	width: 2.5rem;
	height: 2.5rem;
	margin: 0 1rem;
}
.NavItem img {
	width: 1rem;
	height: 1rem;
	margin-left: auto;
}

.Active div {
	background-color: #e5e5e5;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	color: #4e4e74;
}

.NavItem:hover {
	background-color: #e5e5e5;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	text-decoration: none;
	color: #4e4e74;
}

.NavItem:active,
.NavItem:focus {
	text-decoration: none;
}

/* .NavItem:hover .SubMenu { */
/* display: block; */
/* transform: translateX(0); */
/* height: max-content;
	opacity: 1;
} */

.SubMenu {
	/* display: none; */
	/* transform: translateX(-100%); */
	height: max-content;
	margin-left: 4.5rem;
	/* opacity: 0; */
	transition: all ease-in-out 0.5s;
	/* width: 100%; */
}

.SubMenu a {
	font-size: 1.4rem;
	padding: 1rem 0;
	padding-left: 5px;
	margin-bottom: 0px;
	background-color: #e5e5e5;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom: 1px solid #fff;
	text-decoration: none;
	font-family: inherit;
}

.SubMenu a:hover {
	text-decoration: none;
}
