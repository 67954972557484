.FormContainer {
	background-color: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	backdrop-filter: blur(5px);
}

.Form {
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	height: 50%;
	min-width: 50%;
	justify-content: center;
	align-items: center;
}

.Logo {
	margin-bottom: 3rem;
}

.Form input {
	background: transparent;
	border: none;
	outline: none;
	border-bottom: 2px solid #353561;
	width: 80%;
	text-align: center;
	padding: 5px;
	margin-bottom: 1.5rem;
	font-family: inherit;
	background-color: #fff;
	transition: all ease-in-out 0.8s;
}

.Form input:focus {
	border-bottom: 2px solid #ef4871;
	outline: none;
	background-color: #fff;
}

.Form p {
	color: #ef4871;
	font-weight: 700;
	margin-bottom: 1rem;
}

.Loader {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Form button {
	font-family: inherit;
	color: #fff;
	background-color: #353561;
	padding: 8px 36px;
	font-size: 1.6rem;
	font-weight: 700;
	border-radius: 3px;
	border: none;
	outline: none;
	text-transform: capitalize;
}
